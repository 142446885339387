import * as React from 'react';
import Layout from '../components/layout';
import {
  CurrencyDollarIcon,
  WrenchScrewdriverIcon,
  UserGroupIcon,
} from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import HeroImage from '../images/hero-about.png';
import TechnologySection from '../sections/about/Technology';
import HistorySection from '../sections/about/History';
import TeamSection from '../sections/about/Team';
import OrganizationSection from '../sections/about/Organization';
import InfoSecuritySection from '../sections/about/InfoSecurity';
import ProCert from '../sections/about/ProCert';

const AboutPage = () => {
  const { t } = useTranslation('about');
  const features = [
    {
      name: `${t('innovation.name')}`,
      Icon: CurrencyDollarIcon,
      feature: `${t('innovation.feature')}`,
    },
    {
      name: `${t('development.name')}`,
      Icon: WrenchScrewdriverIcon,
      feature: `${t('development.feature')}`,
    },
    {
      name: `${t('service.name')}`,
      Icon: UserGroupIcon,
      feature: `${t('service.feature')}`,
    },
  ];
  return (
    <Layout pageTitle={t('title')}>
      <div className="relative w-full m-auto object-center overflow-hidden">
        <img className="w-full h-96 object-cover" src={HeroImage} alt="" />
        <div className="absolute inset-0 max-w-5xl m-auto">
          <div className="absolute left-[2rem] top-[2rem]">
            <h1 className="m-1 text-3xl text-white">{t('title')}</h1>
          </div>
        </div>
      </div>
      <div className="pb-16 overflow-hidden lg:pb-16">
        <div className="flex flex-col lg:flex-row mx-auto max-w-6xl bg-[#f2f1f0] about__descrip">
          <div className="grid lg:grid-cols-3 about__items bg-[#687393]">
            {features.map((item) => (
              <div className="flex flex-col py-6 px-2">
                <div className="flex flex-col">
                  <div className="w-fit m-auto p-1 rounded-full border-4 border-[#ecdeb2]">
                    <div className="w-fit p-2 rounded-full border-2 border-[#ecdeb2]">
                      <item.Icon className="block text-white size-12 sm:size-20" />
                    </div>
                  </div>
                  <h3 className="p-1 text-center text-[#ecdeb2] text-lg">
                    {item.name}
                  </h3>
                </div>
                <div className="">
                  <div className="relative m-2 text-center text-white text-sm">
                    <p>{item.feature}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="flex px-8 py-8 text-base text-[#687393]">
            <p className="m-auto max-w-lg">{t('introduce.description')}</p>
          </div>
        </div>
      </div>
      <TechnologySection />
      <HistorySection />
      <TeamSection />
      <OrganizationSection />
      <InfoSecuritySection />
      <ProCert />
    </Layout>
  );
};

export default AboutPage;
