import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import OrganizationTree from '../../images/about/organization.svg';
import OrganizationImage from '../../images/about/organization.jpg';

const Section = ({ title, children }) => {
  const { t } = useTranslation('about');
  return (
    <section id="organization">
      <div className="bg-white overflow-hidden">
        <LineDash title={t('organization.title')}>
          <div className="flex">
            <div className="flex flex-col max-w-5xl mx-auto my-7 px-8">
              <img
                className="flex m-4"
                src={OrganizationTree}
                alt="organization"
              />
              <img
                className="flex m-4 rounded-md shadow-xl"
                src={OrganizationImage}
                alt="organization"
              />
            </div>
          </div>
        </LineDash>
      </div>
    </section>
  );
};

export default Section;
