import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import TeamImage from '../../images/about/team.jpg';
import ReactECharts from 'echarts-for-react';

const Section = ({ title, children }) => {
  const { t } = useTranslation('about');
  const note = t('team.note', { returnObjects: true });
  const pie1 = {
    color: ['#e7e7e7', '#6185b0', '#bfc8d1'],
    tooltip: {
      trigger: 'item',
      valueFormatter: (value) => value + `${t('team.pie2.unit')}`,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: `${t('team.pie1.name')}`,
        type: 'pie',
        radius: ['40%', '70%'],
        padAngle: 5,
        itemStyle: {
          borderRadius: 2,
        },
        label: {
          fontSize: 16,
          color: '#6185b0',
        },
        data: t('team.pie1.data', { returnObjects: true }),
      },
      {
        name: `${t('team.pie1.name')}`,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        padAngle: 5,
        itemStyle: {
          borderRadius: 2,
        },
        label: {
          show: true,
          position: 'inside',
          formatter: function (params) {
            return Math.round(params.percent) + ' %';
          },
          color: '#fff',
        },
        emphasis: {
          label: { show: true },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        labelLine: {
          show: true,
          length: 10,
          lineStyle: {
            color: '#333',
          },
        },
        data: t('team.pie1.data', { returnObjects: true }),
      },
    ],
  };
  const pie2 = {
    color: ['#aba8b2', '#e7e7e7', '#605c73'],
    tooltip: {
      trigger: 'item',
      valueFormatter: (value) => value + `${t('team.pie2.unit')}`,
    },
    legend: {
      show: false,
    },
    series: [
      {
        name: `${t('team.pie2.name')}`,
        type: 'pie',
        radius: ['40%', '70%'],
        padAngle: 5,
        itemStyle: {
          borderRadius: 2,
        },
        label: {
          fontSize: 16,
          color: '#605d73',
        },
        data: t('team.pie2.data', { returnObjects: true }),
      },
      {
        name: `${t('team.pie2.name')}`,
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        padAngle: 5,
        itemStyle: {
          borderRadius: 2,
        },
        label: {
          show: true,
          position: 'inside',
          formatter: function (params) {
            return Math.round(params.percent) + ' %';
          },
          color: '#fff',
        },
        emphasis: {
          label: { show: true },
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
        labelLine: {
          show: true,
          length: 10,
          lineStyle: {
            color: '#333',
          },
        },
        data: t('team.pie2.data', { returnObjects: true }),
      },
    ],
  };
  const description = t('team.description', { returnObjects: true });
  const featurepie = {
    color: ['#d9d5ba', '#b9e1e9', '#d9d9d9', '#bfc8d1', '#b6cdc4', '#dceae3'],
    tooltip: { show: false },
    legend: {
      show: false,
    },
    series: [
      {
        type: 'pie',
        radius: '90%',
        label: { show: false },
        itemStyle: {
          borderColor: '#ffffff',
          borderWidth: 5,
        },
        data: [
          { value: 1 },
          { value: 1 },
          { value: 1 },
          { value: 1 },
          { value: 1 },
          { value: 1 },
        ],
        emphasis: {
          itemStyle: {
            borderWidth: 0,
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)',
          },
        },
      },
    ],
  };
  const feature = t('team.feature', { returnObjects: true });
  return (
    <section id="team">
      <div className="bg-white overflow-hidden">
        <div className="flex max-w-6xl mx-auto my-4 px-8 justify-center">
          <div className="relative grid grid-cols-1 gap-8 xl:grid-cols-2 xl:gap-x-96 xl:gap-y-16">
            <div className="flex flex-col p-4 bg-[#dceae3] xl:bg-inherit rounded-2xl xl:ml-16 xl:-mr-16">
              <p className="text-lg m-auto text-[#97afa3] xl:text-[#dceae3]">
                {feature[0].title}
              </p>
              <p
                className="text-base m-auto text-[#605d73]"
                dangerouslySetInnerHTML={{ __html: feature[0].description }}
              ></p>
            </div>
            <div className="flex flex-col p-4 bg-[#d9d5ba] xl:bg-inherit rounded-2xl xl:-ml-16 xl:mr-16">
              <p className="text-lg m-auto text-[#a59962] xl:text-[#d9d5ba]">
                {feature[1].title}
              </p>
              <p
                className="text-base m-auto text-[#605d73]"
                dangerouslySetInnerHTML={{ __html: feature[1].description }}
              ></p>
            </div>
            <div className="flex flex-col p-4 bg-[#b6cdc4] xl:bg-inherit rounded-2xl">
              <p className="text-lg m-auto text-[#7d8984] xl:text-[#b6cdc4]">
                {feature[2].title}
              </p>
              <p
                className="text-base m-auto text-[#605d73]"
                dangerouslySetInnerHTML={{ __html: feature[2].description }}
              ></p>
            </div>
            <div className="flex flex-col p-4 bg-[#b9e1e9] xl:bg-inherit rounded-2xl">
              <p className="text-lg m-auto text-[#74999e] xl:text-[#b9e1e9]">
                {feature[3].title}
              </p>
              <p
                className="text-base m-auto text-[#605d73]"
                dangerouslySetInnerHTML={{ __html: feature[3].description }}
              ></p>
            </div>
            <div className="flex flex-col p-4 bg-[#bfc8d1] xl:bg-inherit rounded-2xl xl:ml-16 xl:-mr-16">
              <p className="text-lg m-auto text-[#7e8184] xl:text-[#bfc8d1]">
                {feature[4].title}
              </p>
              <p
                className="text-base m-auto text-[#605d73]"
                dangerouslySetInnerHTML={{ __html: feature[4].description }}
              ></p>
            </div>
            <div className="flex flex-col p-4 bg-[#d9d9d9] xl:bg-inherit rounded-2xl xl:-ml-16 xl:mr-16">
              <p className="text-lg m-auto text-[#a0a0a0] xl:text-[#d9d9d9]">
                {feature[5].title}
              </p>
              <p
                className="text-base m-auto text-[#605d73]"
                dangerouslySetInnerHTML={{ __html: feature[5].description }}
              ></p>
            </div>
            <div className="absolute xl:flex inset-0 justify-center items-center hidden">
              <ReactECharts
                option={featurepie}
                style={{ height: '90%', width: '90%' }}
              />
            </div>
          </div>
        </div>
        <LineDash title={t('team.title')}>
          <div className="flex flex-col max-w-5xl mx-auto my-4 px-8">
            <div className="flex flex-col my-2 mx-auto text-lg text-[#687393]">
              {description.map((item) => (
                <div className="flex flex-row justify-center items-center font-medium">
                  <p className="min-w-24 text-[#6185b0] justify-self-center">{item.key}</p>
                  <p
                    className="max-w-96 text-base"
                    dangerouslySetInnerHTML={{ __html: item.value }}
                  ></p>
                </div>
              ))}
            </div>
            <div className="flex flex-col lg:flex-row bg-[#f2f1f0] rounded-3xl p-8">
              <div className="flex flex-col m-2 lg:w-1/2">
                <img
                  className="rounded-md shadow-xl"
                  src={TeamImage}
                  alt="team"
                />
                <div className="flex flex-row">
                  {note.map((item) => (
                    <div className="flex flex-col mx-auto text-sm text-[#605d73] text-center mt-2">
                      <p>{item.name}</p>
                      <p>{item.title}</p>
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex flex-col mx-2 my-auto lg:w-1/2">
                <div className="flex flex-col my-2">
                  <div className="flex">
                    <ReactECharts
                      option={pie1}
                      style={{ height: '200px', width: '300px' }}
                    />
                  </div>
                  <div className="flex justify-end">
                    <ReactECharts
                      option={pie2}
                      style={{ height: '200px', width: '300px' }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </LineDash>
      </div>
    </section>
  );
};

export default Section;
