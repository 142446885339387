import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';

const Section = ({ title, children }) => {
  const { t } = useTranslation('about');
  return (
    <section id="proCert">
      <div className="bg-white h-fit block mb-7">
        <LineDash title={t('proCert.title')}>
          <div className="flex">
            <div
                className="w-full h-fit flex flex-col items-center justify-items-center text-[#687393] font-medium"
            >
                <p 
                    dangerouslySetInnerHTML={{ __html: t('proCert.content') }}
                    className="text-center leading-8"
                >
                </p>
            </div>
          </div>
        </LineDash>
      </div>
    </section>
  );
};

export default Section;
