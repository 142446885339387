import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import TechnologyImage from '../../images/about/technology.jpg';

const Section = () => {
  const { t } = useTranslation('about');
  return (
    <section id="technology">
      <LineDash title={t('technology.title')}>
        <div className="flex flex-col w-full max-w-5xl mx-auto lg:flex-row">
          <div className="flex flex-col w-full lg:w-1/2 p-4">
            <img
              className="flex w-full object-cover rounded-lg shadow-xl"
              src={TechnologyImage}
              alt="technology"
            />
            <p className="text-sm text-[#687393]">{t('technology.note')}</p>
          </div>
          <div className="flex flex-col w-full lg:w-1/2 p-2 texe-base text-[#687393]">
            <div className="flex mx-6 my-auto p-6">
              <p>{t('technology.description')}</p>
            </div>
            <div className="flex mx-auto my-6 p-2 rounded-full bg-[#ecdeb2]">
              <span>{t('technology.keyword')}</span>
            </div>
          </div>
        </div>
      </LineDash>
    </section>
  );
};

export default Section;
