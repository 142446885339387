import React from 'react';
import { useTranslation } from 'react-i18next';
import LineDash from '../../components/LineDash';
import Infobg from '../../images/about/infobg.svg';

import Security from '../../images/about/security.svg';
import Complete from '../../images/about/complete.svg';
import Useable from '../../images/about/useable.svg';
import Iso27001 from '../../images/about/iso27001.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import Balance from '../../images/about/balance.svg';
import Key from '../../images/about/key.svg';
import Shield from '../../images/about/shield.svg';
import IEC62443 from '../../images/about/IEC62443.png';

const Section = ({ title, children }) => {
  const { t } = useTranslation('about');
  const infoSecuritys = t('infoSecurity.items', { returnObjects: true });
  const infoImg = [
    Security,
    Complete,
    Useable
  ];
  const infoSecuritys2 = t('infoSecurity.items2', { returnObjects: true });
  const infoImg2 = [
    Shield,
    Key,
    Balance
  ];

  const infoList = [
    {
      left: Iso27001,
      img: infoImg,
      data: infoSecuritys
    },
    {
      left: IEC62443,
      img: infoImg2,
      data: infoSecuritys2
    },
  ];
  return (
    <section id="infoSecurity">
      <div className="bg-white h-fit block">
        <LineDash title={t('infoSecurity.title')}>
          <div className="flex">
            <div
                className="w-full h-full bg-cover bg-center p-10 pt-16"
                style={{ backgroundImage: `url(${Infobg})` }}
              >
                <Swiper
                  spaceBetween={30}
                  centeredSlides={true}
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  navigation={true}
                  modules={[Autoplay, Pagination, Navigation]}
                >
                  {infoList.map((info_item) => (
                    <SwiperSlide>
                      <div className='flex flex-wrap justify-evenly'>
                        <div className='w-[45%] flex flex-wrap justify-center'>
                          <div className='bg-white/80 w-[40%] p-10 rounded-[50px] flex flex-wrap justify-center'>
                            <img
                              className="object-contain"
                              src={info_item.left}
                              alt=""
                            />
                          </div>
                        </div>
                        <div className='gap-5 flex flex-nowrap flex-col justify-center'>
                          {info_item.data.map((item, index) => (
                            <div className='w-[auto] inline-flex gap-5'>
                              <img
                                className="max-w-24 object-cover"
                                src={info_item.img[index]}
                                alt=""
                              />
                              <div className='text-[#687393] font-medium flex flex-col flex-wrap gap-2 justify-center'>
                                <div className='text-3xl font-bold'>{item.title}</div>
                                <div className='text-xl'>{item.content}</div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
          </div>
        </LineDash>
      </div>
    </section>
  );
};

export default Section;
